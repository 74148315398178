//$kustec-primary: #005497;
//$primaryColor: $kustec-primary;

@import "~primeicons/primeicons.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeflex/primeflex";
@import "theme.css";

//scale
html {
  font-size: 14px;
}


